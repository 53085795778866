.App {
  padding: 20px 60px 100px 60px;
  min-height: -webkit-fill-available;
}

#copied_container {
  position: absolute;
  text-align: center;
  position: absolute;
  left: 50%;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.transition {
  transition: background 1s ease-in-out
}

#copied {
  background: #ffffff;
  margin-top: 50px;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.22);
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.fadeOut {
  opacity: 0;
  visibility: hidden
}

.fadeIn {
  opacity: 1;
  visibility: visible
}